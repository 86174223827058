<template>
  <div class="admin">

    <div class="admin-area">

      <div class="row row-gutter-20">
        <div class="col-6">
          <admin-nav></admin-nav>
        </div>
        <div class="col-18">

          <header class="admin-header cf">
            <ul class="nav nav-header cf">
              <li><router-link to="/admin/reservations">Reservierungen</router-link></li>
              <li>Reservierung hinzufügen</li>
            </ul>
            <div class="header-actions">
              <p><router-link to="/admin/reservations" class="button button-grey button-small">Alle Reservierungen ›</router-link></p>
            </div>
          </header>

          <!--<p>Rechnungsnummer fortlaufend auf Basis bestehender ID</p>
          <p>Standort ergänzen um Ansprechpartner (nur intern). PDF Generierungsformular für Provision, Extra Punkt %-Satz flexibel</p>
          <p>Selbstbeteiligung individuell festlegen, nicht sichtbar sondern nur für Admin Bereich</p>-->

          <form @submit="create_reservation" class="form" method="post" style="margin-top:20px">

            <h3>Abholung und Rückgabe</h3>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="form-wrap select-wrap">
                  <label for="pickup_location">Abholung in</label>
                  <select v-model="reservation.pickup_location_id" id="pickup_location">
                    <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">
                      {{ location.name }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-12">
                <div class="form-wrap select-wrap">
                  <label for="return_location">Rückgabe in</label>
                  <select v-model="reservation.return_location_id" id="return_location">
                    <option v-for="location in locations" v-bind:key="location.id" v-bind:value="location.id">
                      {{ location.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="row row-gutter-20">
              <div class="col-12">
                <div class="row row-gutter-20">
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="pickup_date">Abholung Datum</label>
                      <v-date-picker v-model="reservation.pickup_date" locale="de">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="form-input"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>
                    </div>

                  </div>
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="pickup_time">Abholung Uhrzeit</label>
                      <select v-model="reservation.pickup_time" id="pickup_time">
                        <option value="00">00:00 Uhr</option>
                        <option value="01">01:00 Uhr</option>
                        <option value="02">02:00 Uhr</option>
                        <option value="03">03:00 Uhr</option>
                        <option value="04">04:00 Uhr</option>
                        <option value="05">05:00 Uhr</option>
                        <option value="06">06:00 Uhr</option>
                        <option value="07">07:00 Uhr</option>
                        <option value="08">08:00 Uhr</option>
                        <option value="09">09:00 Uhr</option>
                        <option value="10">10:00 Uhr</option>
                        <option value="11">11:00 Uhr</option>
                        <option value="12">12:00 Uhr</option>
                        <option value="13">13:00 Uhr</option>
                        <option value="14">14:00 Uhr</option>
                        <option value="15">15:00 Uhr</option>
                        <option value="16">16:00 Uhr</option>
                        <option value="17">17:00 Uhr</option>
                        <option value="18">18:00 Uhr</option>
                        <option value="19">19:00 Uhr</option>
                        <option value="20">20:00 Uhr</option>
                        <option value="21">21:00 Uhr</option>
                        <option value="22">22:00 Uhr</option>
                        <option value="23">23:00 Uhr</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="row row-gutter-20">
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="return_date">Rückgabe Datum</label>
                      <v-date-picker v-model="reservation.return_date" :min-date='this.reservation.pickup_date' locale="de">
                        <template v-slot="{ inputValue, inputEvents }">
                          <input
                            class="form-input"
                            :value="inputValue"
                            v-on="inputEvents"
                          />
                        </template>
                      </v-date-picker>
                    </div>

                  </div>
                  <div class="col-12">

                    <div class="form-wrap select-wrap">
                      <label for="return_time">Rückgabe Uhrzeit</label>
                      <select v-model="reservation.return_time" id="return_time">
                        <option value="00">00:00 Uhr</option>
                        <option value="01">01:00 Uhr</option>
                        <option value="02">02:00 Uhr</option>
                        <option value="03">03:00 Uhr</option>
                        <option value="04">04:00 Uhr</option>
                        <option value="05">05:00 Uhr</option>
                        <option value="06">06:00 Uhr</option>
                        <option value="07">07:00 Uhr</option>
                        <option value="08">08:00 Uhr</option>
                        <option value="09">09:00 Uhr</option>
                        <option value="10">10:00 Uhr</option>
                        <option value="11">11:00 Uhr</option>
                        <option value="12">12:00 Uhr</option>
                        <option value="13">13:00 Uhr</option>
                        <option value="14">14:00 Uhr</option>
                        <option value="15">15:00 Uhr</option>
                        <option value="16">16:00 Uhr</option>
                        <option value="17">17:00 Uhr</option>
                        <option value="18">18:00 Uhr</option>
                        <option value="19">19:00 Uhr</option>
                        <option value="20">20:00 Uhr</option>
                        <option value="21">21:00 Uhr</option>
                        <option value="22">22:00 Uhr</option>
                        <option value="23">23:00 Uhr</option>
                      </select>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <div class="form-wrap">
              <input v-model="reservation.workshop_mode" @change="toggle_workshop_mode" type="checkbox" style="display:inline-block;vertical-align:middle;margin-right:6px" id="workshop_mode">
              <label for="workshop_mode" style="display:inline-block;vertical-align:middle;margin-bottom:0">Werkstatt Modus?</label>
            </div>

            <div v-if="reservation.workshop_mode">

              <h3>Fahrzeug</h3>

              <div class="form-wrap select-wrap">
                <label for="vehicle_categories">Fahrzeug</label>
                <select v-model="reservation.vehicle_id" id="vehicles">
                  <option v-for="vehicle in vehicles" v-bind:key="vehicle.id" v-bind:value="vehicle.id">{{ vehicle.license_plate }}</option>
                </select>
              </div>

              <div class="form-wrap">
                <label for="workshop_details">Werkstatt Infos</label>
                <textarea v-model="reservation.workshop_details" class="form-input" rows="3" id="workshop_details"></textarea>
              </div>

              <p>
                <a @click="create_reservation" class="button button-orange button-100 button-small">Werkstattmodus aktivieren ›</a>
              </p>

            </div>
            <div v-else>

              <h3>Fahrzeug</h3>

              <div class="form-wrap select-wrap">
                <label for="vehicle_categories">Fahrzeugkategorie</label>
                <select v-model="reservation.category_id" @change="vehicle_category_change($event)" id="vehicle_categories">
                  <option v-for="category in categories" v-bind:key="category.id" v-bind:value="category.id">{{ category.name }}</option>
                </select>
              </div>

              <div class="form-wrap select-wrap">
                <label for="vehicle_categories">Fahrzeug</label>
                <select v-model="reservation.vehicle_id" id="vehicles">
                  <option v-for="vehicle in vehicles" v-bind:key="vehicle.id" v-bind:value="vehicle.id">{{ vehicle.license_plate }}</option>
                </select>
              </div>

              <div v-if="reservation.category_id">

                <h2>Versicherung & Extras</h2>

                <h3>Versicherung</h3>

                <div v-for="extra in available_extras.insurances" v-bind:key="extra.id">
                  <label :for="extra.id" @click="set_insurance(extra)" class="extra-wrap cf">
                    <input type="radio" :id="extra.id" :value="extra.id" v-model="reservation.insurance">
                    <div class="extra-wrap-body">
                      <h4>{{ extra.feature.name }}</h4>
                      <p>{{ extra.feature.description }}</p>
                    </div>
                    <p class="price">
                      {{ parseFloat(extra.feature.price).toFixed(2) }} €
                      <small v-if="extra.feature.price_type == 'per_day'" style="display:block;text-align:right">Pro Tag</small>
                      <small v-if="extra.feature.price_type == 'once'" style="display:block;text-align:right">Einmalig</small>
                    </p>
                  </label>
                </div>

                <h3>Kilometer</h3>

                <div v-for="extra in available_extras.kilometer" v-bind:key="extra.id">
                  <label :for="extra.id" @click="set_kilometer(extra)" class="extra-wrap cf">
                    <input type="radio" :id="extra.id" :value="extra.id" v-model="reservation.kilometer">
                    <div class="extra-wrap-body">
                      <h4>{{ extra.feature.name }}</h4>
                      <p>{{ extra.feature.description }}</p>
                    </div>
                    <p class="price">
                      {{ parseFloat(extra.feature.price).toFixed(2) }} €
                      <small v-if="extra.feature.price_type == 'per_day'" style="display:block;text-align:right">Pro Tag</small>
                      <small v-if="extra.feature.price_type == 'once'" style="display:block;text-align:right">Einmalig</small>
                    </p>
                  </label>
                </div>

                <h3>Extras</h3>

                <div v-for="extra in available_extras.extras" v-bind:key="extra.id">
                  <label :for="extra.id" class="extra-wrap cf">
                    <input type="checkbox" @click="set_extras()" :id="extra.id" :value="extra.id" v-model="extras.extras">
                    <div class="extra-wrap-body">
                      <h4>{{ extra.feature.name }}</h4>
                      <p>{{ extra.feature.description }}</p>
                    </div>
                    <p class="price">
                      {{ parseFloat(extra.feature.price).toFixed(2) }} €
                      <small v-if="extra.feature.price_type == 'per_day'" style="display:block;text-align:right">Pro Tag</small>
                      <small v-if="extra.feature.price_type == 'once'" style="display:block;text-align:right">Einmalig</small>
                    </p>
                  </label>
                </div>

              </div>

              <p>
                <a @click="toggle_prices" class="button button-orange button-100 button-small">Preise berechnen ›</a>
              </p>
            </div>
            <div v-if="show_prices" style="margin-top:40px">

              <h3>Persönliche Daten</h3>

              <div class="form-wrap select-wrap">
                <label for="user_id">Entweder bestehenden User auswählen</label>
                <select v-model="reservation.user_id" @change="user_change($event)" id="vehicles">
                  <option v-for="user in users" v-bind:key="user.id" v-bind:value="user.id">{{ user.last_name }}, {{ user.first_name }} ({{ user.email }}) – <span v-if="user.trust_level == 'good'">Vertrauen: Gut</span><span v-if="user.trust_level == 'medium'">Vertrauen: Mittel</span><span v-if="user.trust_level == 'bad'">Vertrauen: Schlecht</span> <span v-if="user.trust_description">(Info: {{ user.trust_description }})</span></option>
                </select>
              </div>

              <div class="form-wrap">
                <input v-model="reservation.user_create" type="checkbox" style="display:inline-block;vertical-align:middle;margin-right:6px" id="user_create">
                <label for="user_create" style="display:inline-block;vertical-align:middle;margin-bottom:0">Oder Checkbox anhaken um neuen User zu erstellen</label>
              </div>

              <div class="form-wrap">
                <label for="company">Unternehmen</label>
                <input v-model="reservation.company" id="company" class="form-input">
              </div>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="first_name">Vorname</label>
                    <input v-model="reservation.first_name" id="first_name" class="form-input">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="last_name">Nachname</label>
                    <input v-model="reservation.last_name" id="last_name" class="form-input">
                  </div>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-16">
                  <div class="form-wrap">
                    <label for="street_name">Straße</label>
                    <input v-model="reservation.street_name" class="form-input" type="text" name="street_name" id="street_name">
                  </div>
                </div>
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="street_number">Hausnummer</label>
                    <input v-model="reservation.street_number" class="form-input" type="text" name="street_number" id="street_number">
                  </div>
                </div>
              </div>

              <div class="row row-gutter-20">
                <div class="col-8">
                  <div class="form-wrap">
                    <label for="zip_code">Postleitzahl</label>
                    <input v-model="reservation.zip_code" class="form-input" type="text" name="zip_code" id="zip_code">
                  </div>
                </div>
                <div class="col-16">
                  <div class="form-wrap">
                    <label for="city">Stadt</label>
                    <input v-model="reservation.city" class="form-input" type="text" name="city" id="city">
                  </div>
                </div>
              </div>

              <div class="form-wrap">
                <label for="birth_date">Geburtsdatum</label>
                <input v-model="reservation.birth_date" class="form-input" type="text" name="birth_date" id="birth_date" placeholder="01.01.1990">
              </div>

              <h3>Kontaktmöglichkeiten</h3>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="email">E-Mail-Adresse</label>
                    <input v-model="reservation.email" id="email" class="form-input">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="phone">Telefonnummer</label>
                    <input v-model="reservation.phone_number" id="phone" class="form-input">
                  </div>
                </div>
              </div>

              <h3>Sonstiges</h3>

              <div class="row row-gutter-20">
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="order_number">DHL Ordernummer</label>
                    <input v-model="reservation.order_number" id="email" class="form-input">
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-wrap">
                    <label for="aks">DHL AKZ</label>
                    <input v-model="reservation.aks" id="aks" class="form-input">
                  </div>
                </div>
              </div>

            </div>


            <div v-if="show_prices">
              <h3>Übersicht</h3>

              <table v-if="vehicle.id" class="table" style="border: 1px solid #dee2e6;margin-bottom:40px">
                <thead>
                  <tr>
                    <th width="50%">Beschreibung</th>
                    <th width="50%" style="text-align:right">Kosten</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Fahrzeugmiete für {{ period.duration }} Tage</td>
                    <td style="text-align:right">
                      <div class="form-wrap" style="margin-bottom:0">
                        <input v-model="vehicle.price_calculated" class="form-input" type="text" style="font-size:12px;padding:5px 10px;display:inline-block;width:100px"> €
                      </div>
                    </td>
                  </tr>
                  <tr v-if="extras.pricing.insurance.name">
                    <td>Versicherung ({{ extras.pricing.insurance.name }})</td>
                    <td v-if="extras.pricing.insurance.price_type == 'once'" style="text-align:right">{{ parseFloat(extras.pricing.insurance.price).toFixed(2) }} €</td>
                    <td v-if="extras.pricing.insurance.price_type == 'per_day'" style="text-align:right">{{ parseFloat(extras.pricing.insurance.price*period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr v-if="extras.pricing.kilometer.name">
                    <td>Kilometer ({{ extras.pricing.kilometer.name }})</td>
                    <td v-if="extras.pricing.kilometer.price_type == 'once'" style="text-align:right">{{ parseFloat(extras.pricing.kilometer.price).toFixed(2) }} €</td>
                    <td v-if="extras.pricing.kilometer.price_type == 'per_day'" style="text-align:right">{{ parseFloat(extras.pricing.kilometer.price*period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr v-for="extra in extras.pricing.extras" v-bind:key="extra.name">
                    <td>{{ extra.name }}</td>
                    <td v-if="extra.price_type == 'once'" style="text-align:right">{{ parseFloat(extra.price).toFixed(2) }} €</td>
                    <td v-if="extra.price_type == 'per_day'" style="text-align:right">{{ parseFloat(extra.price*period.duration).toFixed(2) }} €</td>
                  </tr>
                  <tr style="background:#F6F9FD;color:#495057;border-top:3px solid rgb(222, 226, 230)">
                    <td><strong>Gesamtbetrag (Netto)</strong></td>
                    <td style="text-align:right">
                      <div class="form-wrap" style="margin-bottom:0">
                        <input v-model="extras.pricing.sum_net" class="form-input" type="text" style="font-size:12px;padding:5px 10px;display:inline-block;width:100px"> €
                      </div>
                    </td>
                  </tr>
                  <tr style="background:#F6F9FD;color:#495057">
                    <td><strong>+ {{ tax_rate }}% MwSt</strong></td>
                    <td style="text-align:right">
                      <div class="form-wrap" style="margin-bottom:0">
                        <input v-model="extras.pricing.sum_tax" class="form-input" type="text" style="font-size:12px;padding:5px 10px;display:inline-block;width:100px"> €
                      </div>
                    </td>
                  </tr>
                  <tr style="background:#444;color:#fff">
                    <td><strong>GESAMTBETRAG</strong></td>
                    <td style="text-align:right"><strong>
                      <div class="form-wrap" style="margin-bottom:0">
                        <input v-model="extras.pricing.sum_gross" class="form-input" type="text" style="font-size:12px;padding:5px 10px;display:inline-block;width:100px"> €
                      </div>
                    </strong></td>
                  </tr>

                </tbody>
              </table>


              <p>
                <input type="submit" value="Reservierung erstellen ›" class="button button-orange button-large button-100">
              </p>
            </div>

          </form>


        </div>
      </div>

    </div>

  </div>
</template>

<script>
import axios from 'axios'
import format from 'date-fns/format'
import AdminNav from '../../../components/admin_nav'
import moment from 'moment'

export default {
  name: 'admin_reservations',
  components: {
    AdminNav
  },
  data () {
    return {
      show_prices: false,
      errors: [],
      reservation: {
        extras: [],
        accepted_terms: true,
        accepted_privacy: true,
        payment_option: 'admin',
        company: "",
        first_name: "",
        last_name: "",
        street_name: "",
        street_number: "",
        zip_code: "",
        city: "",
        email: "",
        phone_number: "",
        workshop_mode: false
      },
      period: {
        duration: 1
      },
      vehicle: {},
      users: [],
      locations: [],
      categories: [],
      vehicles: [],
      selected_user: {},
      available_extras: {
        insurances: [],
        kilometer: [],
        extras: []
      },
      extras: {
        extras: [],
        pricing: {
          insurance: {},
          kilometer: {},
          extras: [],
          sum_net: "",
          sum_tax: "",
          sum_gross: ""
        }
      },
      user_selection: "",
      tax_rate: 16
    }
  },
  methods: {
    check_query_params() {
      if (this.$route.query.pickup_time) {
        this.reservation.pickup_time = this.$route.query.pickup_time;
      }
      if (this.$route.query.return_time) {
        this.reservation.return_time = this.$route.query.return_time;
      }
      if (this.$route.query.pickup_date_unix) {
        this.reservation.pickup_date = moment.unix(this.$route.query.pickup_date_unix).toDate();
        this.period.pickup_date = moment.unix(this.$route.query.pickup_date_unix).toDate();
      } else {
        this.reservation.pickup_date = new Date();
        this.period.pickup_date = new Date();
      }
      if (this.$route.query.pickup_date) {
        this.reservation.pickup_date = new Date(this.$route.query.pickup_date);
        this.period.pickup_date = new Date(this.$route.query.pickup_date);
      } else {
        if (this.$route.query.pickup_date_unix) {
          this.reservation.pickup_date = moment.unix(this.$route.query.pickup_date_unix).toDate();
          this.period.pickup_date = moment.unix(this.$route.query.pickup_date_unix).toDate();
        } else {
          this.reservation.pickup_date = new Date();
          this.period.pickup_date = new Date();
        }
      }
      if (this.$route.query.return_date) {
        this.reservation.return_date = new Date(this.$route.query.return_date);
        this.period.return_date = new Date(this.$route.query.return_date);
      } else {
        if (this.$route.query.return_date_unix) {
          this.reservation.return_date = moment.unix(this.$route.query.return_date_unix).toDate();
          this.period.return_date = moment.unix(this.$route.query.return_date_unix).toDate();
        } else {
          this.reservation.return_date = new Date();
          this.period.return_date = new Date();
        }
      }
      if (this.$route.query.category_id) {
        this.reservation.category_id = this.$route.query.category_id;
        this.get_vehicle_category(this.$route.query.category_id);
      }
      if (this.$route.query.vehicle_id) {
        this.reservation.vehicle_id = this.$route.query.vehicle_id;
      }
      if (this.$route.query.location_id) {
        this.reservation.pickup_location_id = this.$route.query.location_id;
        this.reservation.return_location_id = this.$route.query.location_id;
      }
      if (this.$route.query.pickup_location_id) {
        this.reservation.pickup_location_id = this.$route.query.pickup_location_id;
      }
      if (this.$route.query.return_location_id) {
        this.reservation.return_location_id = this.$route.query.return_location_id;
      }
      if (this.$route.query.user_id) {
        this.reservation.user_id = this.$route.query.user_id;
      }
      if (this.$route.query.company) {
        this.reservation.company = this.$route.query.company;
      }
      if (this.$route.query.first_name) {
        this.reservation.first_name = this.$route.query.first_name;
      }
      if (this.$route.query.last_name) {
        this.reservation.last_name = this.$route.query.last_name;
      }
      if (this.$route.query.street_name) {
        this.reservation.street_name = this.$route.query.street_name;
      }
      if (this.$route.query.street_number) {
        this.reservation.street_number = this.$route.query.street_number;
      }
      if (this.$route.query.zip_code) {
        this.reservation.zip_code = this.$route.query.zip_code;
      }
      if (this.$route.query.city) {
        this.reservation.city = this.$route.query.city;
      }
      if (this.$route.query.birth_date) {
        this.reservation.birth_date = this.$route.query.birth_date;
      }
      if (this.$route.query.email) {
        this.reservation.email = this.$route.query.email;
      }
      if (this.$route.query.phone_number) {
        this.reservation.phone_number = this.$route.query.phone_number;
      }
      if (this.$route.query.phone_number) {
        this.reservation.phone_number = this.$route.query.phone_number;
      }
      if (this.$route.query.vehicle_id) {
        this.reservation.vehicle_id = this.$route.query.vehicle_id;
      }
    },
    set_dates() {
    },
    get_data() {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.users = response.data.customers;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/locations', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.locations = response.data.locations;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        console.log(response);
        this.categories = response.data.vehicle_categories;
      })
      .catch(error => {
        console.log(error);
      })

    },
    get_extras() {
      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.reservation.vehicle.name+'/extras', {})
      .then(response => {
        this.available_extras.insurances = [];
        this.available_extras.kilometer = [];
        this.available_extras.extras = [];
        response.data.vehicle_extras.forEach(value => {
          if (value.feature.category == 'insurance') {
            this.available_extras.insurances.push(value);
          }
          if (value.feature.category == 'kilometer') {
            this.available_extras.kilometer.push(value);
          }
          if (value.feature.category == 'extra') {
            this.available_extras.extras.push(value);
          }
        })
      })
      .catch(error => {
        console.log(error);
      })
    },
    create_reservation: function (e) {
      e.preventDefault();

      var items = [];

      items.push({
        name: "Fahrzeugmiete für "+this.period.duration+" Tage",
        price: parseFloat(this.vehicle.price_calculated).toFixed(2)
      })

      if (this.extras.pricing.insurance.price_type == 'once') {
        items.push({
          name: "Versicherung ("+this.extras.pricing.insurance.name+")",
          price: parseFloat(this.extras.pricing.insurance.price).toFixed(2),
          description: this.extras.pricing.insurance.description
        })
      } else {
        items.push({
          name: "Versicherung ("+this.extras.pricing.insurance.name+")",
          price: (parseFloat(this.extras.pricing.insurance.price)*this.period.duration).toFixed(2),
          description: this.extras.pricing.insurance.description
        })
      }

      if (this.extras.pricing.kilometer.price_type == 'once') {
        items.push({
          name: "Kilometer ("+this.extras.pricing.kilometer.name+")",
          price: parseFloat(this.extras.pricing.kilometer.price).toFixed(2),
          description: this.extras.pricing.kilometer.description
        })
      } else {
        items.push({
          name: "Kilometer ("+this.extras.pricing.kilometer.name+")",
          price: (parseFloat(this.extras.pricing.kilometer.price)*this.period.duration).toFixed(2),
          description: this.extras.pricing.kilometer.description
        })
      }

      this.extras.pricing.extras.forEach(extra => {
        if (extra.price_type == 'once') {
          items.push({
            name: extra.name,
            price: parseFloat(extra.price).toFixed(2),
            description: extra.description
          })
        } else {
          items.push({
            name: extra.name,
            price: (parseFloat(extra.price)*this.period.duration).toFixed(2),
            description: extra.description
          })
        }
      });

      this.reservation.res_items = items;
      this.reservation.sum_net = this.extras.pricing.sum_net,
      this.reservation.sum_tax = this.extras.pricing.sum_tax,
      this.reservation.sum_gross = this.extras.pricing.sum_gross
      this.reservation.tax_rate = this.tax_rate

      axios.post(process.env.VUE_APP_BASE_API+'/v1/admin/reservations', this.reservation, { headers: { Authorization: this.$store.getters.getToken }})
      .then(() => {
        this.$notify({
          group: 'alert',
          type: 'success',
          title: 'Erfolgreich gespeichert.',
        });
        this.$router.push('/admin/reservations');
      })
      .catch(error => {
        error.response.data.errors.forEach(val => {
          this.$notify({
            group: 'alert',
            type: 'error',
            title: val,
          });
        })
      })

    },
    onPickupChange(d) {
      console.log(d);
      this.pickup_timestamp = d.startDate.toDateString("yyyyMMdd");
      this.return_timestamp = d.endDate.toDateString("yyyyMMdd");
    },
    formatDates(dateOne, dateTwo) {
      let formattedDates = ''
      if (dateOne) {
        formattedDates = format(dateOne, this.dateFormat)
      }
      if (dateTwo) {
        formattedDates += ' - ' + format(dateTwo, this.dateFormat)
      }
      return formattedDates
    },
    vehicle_category_change(event) {
      this.get_vehicle_category(event.target.value);
    },
    get_vehicle_category(id) {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles?category_id='+id, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.vehicles = response.data.vehicles;
      })
      .catch(error => {
        console.log(error);
      })

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+id+'/extras', { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.available_extras.insurances = [];
        this.available_extras.kilometer = [];
        this.available_extras.extras = [];
        response.data.vehicle_extras.forEach(value => {
          console.log("EXTRA:")
          console.log(value);
          if (value.feature.category == 'insurance') {
            this.available_extras.insurances.push(value);
          }
          if (value.feature.category == 'kilometer') {
            this.available_extras.kilometer.push(value);
          }
          if (value.feature.category == 'extra') {
            this.available_extras.extras.push(value);
          }
        })
      })
      .catch(error => {
        console.log(error);
      })

    },
    user_change(event) {

      axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/customers/'+event.target.value, { headers: { Authorization: this.$store.getters.getToken }})
      .then(response => {
        this.selected_user = response.data.customer;
        this.reservation.company = response.data.customer.company;
        this.reservation.first_name = response.data.customer.first_name;
        this.reservation.last_name = response.data.customer.last_name;
        this.reservation.street_name = response.data.customer.street_name;
        this.reservation.street_number = response.data.customer.street_number;
        this.reservation.zip_code = response.data.customer.zip_code;
        this.reservation.city = response.data.customer.city;
        this.reservation.email = response.data.customer.email;
        this.reservation.phone_number = response.data.customer.phone_number;
        this.reservation.birth_date = response.data.customer.birth_date;
      })
      .catch(error => {
        console.log(error);
      })

    },
    set_insurance(extra) {
      this.extras.pricing.insurance = {
        name: extra.feature.name,
        price: extra.feature.price,
        price_type: extra.feature.price_type,
        description: extra.feature.description
      }
    },
    set_kilometer(extra) {
      this.extras.pricing.kilometer = {
        name: extra.feature.name,
        price: extra.feature.price,
        price_type: extra.feature.price_type,
        description: extra.feature.description
      }
    },
    set_extras() {
      this.extras.pricing.extras = []
      var available_extras = this.available_extras.extras;
      setTimeout(() => {
        var selected_extras = this.extras.extras;
        available_extras.forEach(val => {
          if (selected_extras.includes(val.id)) {
            this.extras.pricing.extras.push({
              name: val.feature.name,
              price: val.feature.price,
              price_type: val.feature.price_type,
              description: val.feature.description
            })
          }
        })
      }, 200)
    },
    toggle_prices() {

      if (this.extras.pricing.insurance.price_type && this.extras.pricing.kilometer.price_type && this.reservation.pickup_location_id && this.reservation.return_location_id && this.reservation.pickup_date && this.reservation.pickup_time && this.reservation.return_date && this.reservation.return_time) {

        // Determine Duration

        var difference_in_time = this.reservation.return_date.setHours(this.reservation.return_time,0,0,0) - this.reservation.pickup_date.setHours(this.reservation.pickup_time,0,0,0);
        var difference_in_days = difference_in_time / (1000 * 3600 * 24);
        this.period.duration = Math.ceil(difference_in_days);

        // Get category details to calculate

        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicle_categories/'+this.reservation.category_id, { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          this.vehicle = response.data.vehicle_category;

          var price = 0.0;
          var duration = this.period.duration;
          var date = moment(this.reservation.pickup_date).format("d");

          if (duration < 2) {
            if (date == 1) {
              price = this.vehicle.price_monday;
            } else if (date == 2) {
              price = this.vehicle.price_tuesday;
            } else if (date == 3) {
              price = this.vehicle.price_wednesday;
            } else if (date == 4) {
              price = this.vehicle.price_thursday;
            } else if (date == 5) {
              price = this.vehicle.price_friday;
            } else if (date == 6) {
              price = this.vehicle.price_saturday;
            } else if (date == 0) {
              price = this.vehicle.price_sunday;
            } else {
              price = this.vehicle.price_per_day;
            }
          } else if (duration == 2) {
            if (this.vehicle.price_ws_2) {
              if (date == 5) {
                price = this.vehicle.price_lt_2*this.vehicle.price_ws_2;
              } else if (date == 6) {
                price = this.vehicle.price_lt_2*this.vehicle.price_ws_2;
              } else {
                price = this.vehicle.price_lt_2
              }
            } else {
              price = this.vehicle.price_lt_2
            }
          } else if (duration == 3) {
            if (this.vehicle.price_ws_3) {
              if (date == 5) {
                price = this.vehicle.price_lt_3*this.vehicle.price_ws_3;
              } else if (date == 6) {
                price = this.vehicle.price_lt_3*this.vehicle.price_ws_3;
              } else {
                price = this.vehicle.price_lt_3
              }
            } else {
              price = this.vehicle.price_lt_3;
            }
          } else if (duration >= 4 && duration < 5) {
            price = this.vehicle.price_lt_3;
          } else if (duration >= 5 && duration < 7) {
            price = this.vehicle.price_lt_5;
          } else if (duration >= 7 && duration < 14) {
            price = this.vehicle.price_lt_7;
          } else if (duration >= 14 && duration < 30) {
            price = this.vehicle.price_lt_14;
          } else if (duration >= 30) {
            price = this.vehicle.price_lt_30;
          } else {
            price = this.vehicle.price_per_day;
          }

          this.vehicle.price_calculated = parseFloat(price*duration).toFixed(2);

          var sum_gross = 0.0;
          sum_gross = sum_gross + parseFloat(this.vehicle.price_calculated);

          if (this.extras.pricing.insurance.price_type == 'once') {
            sum_gross = sum_gross + parseFloat(this.extras.pricing.insurance.price);
          } else {
            sum_gross = sum_gross + (parseFloat(this.extras.pricing.insurance.price)*this.period.duration);
          }

          if (this.extras.pricing.kilometer.price_type == 'once') {
            sum_gross = sum_gross + parseFloat(this.extras.pricing.kilometer.price);
          } else {
            sum_gross = sum_gross + (parseFloat(this.extras.pricing.kilometer.price)*this.period.duration);
          }

          this.extras.pricing.extras.forEach(val => {
            if (val.price_type == 'once') {
              sum_gross = sum_gross + parseFloat(val.price);
            } else {
              sum_gross = sum_gross + (parseFloat(val.price)*this.period.duration);
            }
          });

          this.extras.pricing.sum_gross = parseFloat(sum_gross).toFixed(2);

          if (moment(this.reservation.return_date).format('YYYY') > 2020) {
            this.tax_rate = 19;
            this.extras.pricing.sum_net = parseFloat(this.extras.pricing.sum_gross/1.19).toFixed(2);
          } else {
            this.tax_rate = 16;
            this.extras.pricing.sum_net = parseFloat(this.extras.pricing.sum_gross/1.16).toFixed(2);
          }

          this.extras.pricing.sum_tax = parseFloat(this.extras.pricing.sum_gross-this.extras.pricing.sum_net).toFixed(2);
          this.show_prices = true;

        })
      } else {
        this.$notify({
          group: 'alert',
          type: 'error',
          title: "Bitte die nötigen Felder ausfüllen.",
        });
      }

    },
    toggle_workshop_mode() {
      if (this.reservation.workshop_mode) {
        axios.get(process.env.VUE_APP_BASE_API+'/v1/admin/vehicles', { headers: { Authorization: this.$store.getters.getToken }})
        .then(response => {
          this.vehicles = response.data.vehicles;

          this.reservation.first_name = "Werkstatt";
          this.reservation.last_name = "Modus";
          this.reservation.birth_date = "01.01.1990";
          this.reservation.email = "werkstatt@intime-autovermietung.de";
          this.reservation.phone_number = "123456";
          this.reservation.street_name = "Werkstattstraße";
          this.reservation.street_number = "1";
          this.reservation.zip_code = "12345";
          this.reservation.city = "Werkstadt";
          this.reservation.category_id = 1;

          this.period.duration = 1;
          this.vehicle.price_calculated = parseFloat(0.0).toFixed(2);
          this.extras.pricing.sum_gross = parseFloat(0.0).toFixed(2);
          this.extras.pricing.sum_net = parseFloat(0.0).toFixed(2);
          this.extras.pricing.sum_tax = parseFloat(0.0).toFixed(2);

        })
      } else {
        //
        this.vehicles = [];

        this.reservation.first_name = "";
        this.reservation.last_name = "";
        this.reservation.birth_date = "";
        this.reservation.email = "";
        this.reservation.phone_number = "";
        this.reservation.street_name = "";
        this.reservation.street_number = "";
        this.reservation.zip_code = "";
        this.reservation.city = "";
        this.reservation.category_id = null;
      }
    }
  },
  mounted () {
    this.check_query_params();
    this.get_data();
    this.set_dates();
  },
}
</script>

<style lang="scss">

</style>
